<template>
  <div class="mt-7">
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <h5 class="mb-1" v-if="ad_account">{{ ad_account.name }}</h5>
    <v-divider></v-divider>
    <v-row class="mt-3 mb-3">
      <v-col>
        <summary-card :generalAdaccountSummary="ad_account" class="min-height" />
      </v-col>
      <v-col>
        <summary-card :adaccountFundingSummary="ad_account" class="min-height" />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="facebook_adaccount_insights">
      <v-col  v-if="facebook_adaccount_insights.length">
        <h4>Account Stats</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="mt-3 mb-2" v-if="facebook_adaccount_insights">
      <v-col>
        <total-card :title="impressions_card_title" v-if="facebook_adaccount_insights.length"
          :stats="facebook_adaccount_insights[0].impressions" />
      </v-col>
      <v-col>
        <total-card :title="cpc_card_title" v-if="facebook_adaccount_insights.length"
          :stats="facebook_adaccount_insights[0].cpc" :currency="ad_account.currency" />
      </v-col>
      <v-col>
        <total-card :title="reach_card_title" v-if="facebook_adaccount_insights.length"
          :stats="facebook_adaccount_insights[0].reach" />
      </v-col>
      <v-col>
        <total-card :title="clicks_card_title" v-if="facebook_adaccount_insights.length"
          :stats="facebook_adaccount_insights[0].clicks" />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mt-2 mb-1">
      <v-col>
        <h4>User Capabilities</h4>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2 mb-3">
      <v-col cols="9">
        <general-card class="pa-5" v-if="ad_account">
          <h5 class="mb-2">Countries</h5>
          <span v-for="(country, i) in ad_account.rf_spec.countries" :key="i" class="text-justify">
            <span class="mr-1">{{ country }},</span>
          </span>
        </general-card>
      </v-col>
      <v-col>
        <general-card class="pa-5" v-if="ad_account">
          <h5 class="mb-2">User Tasks</h5>
          <v-list-item v-for="(task, i) in ad_account.user_tasks" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ task }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </general-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import SummaryCard from "../../../components/Layouts/Cards/SummaryCard.vue";
import TotalCard from "../../../components/Layouts/Cards/TotalCard.vue";

export default {
  data: () => ({
    stopLooping: false,
    adaccountId: null,
    fields: [
      "impressions",
      "ctr",
      "cpc",
      "reach",
      "clicks",
      "conversions",
      "date_start",
      "date_stop",
      "social_spend",
      "spend",
      "engagement_rate_ranking",
    ],

    //Total Card Titles
    impressions_card_title: "Total Impressions",
    general_card_title: "General Ad Account Details",
    cpc_card_title: "CPC",
    reach_card_title: "reach",
    ctr_card_title: "CTR",
    spend_card_title: "Spend",
    clicks_card_title: "Clicks",
    error: null,

    //Dates
    selectedFromDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    selectedToDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    menuFrom: false,
    menuTo: false,
    items: [
      {
        text: "Facebook Ad Accounts",
        disabled: false,
        href: "#/facebook/ad-accounts",
      },
      {
        text: "Single Ad Account",
        disabled: true,
        href: "#",
      },
    ],
  }),
  components: {
    GeneralCard,
    SummaryCard,
    TotalCard,
  },
  methods: {
    async getReportStatus() {
      //Check Report Status
      try {
        await this.$store.dispatch("facebook/checkReportStatus", {
          report_run_id: this.report_run_id,
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
  async mounted() {
    //Get Adaccount General Details
    this.adaccountId = this.$route.params.id;
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("facebook/getAdaccount", this.adaccountId.substring(4));
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    //Create Report Run ID
    try {
      await this.$store.dispatch("facebook/getAdaccountInsightsReportId", {
        adAccountId: this.adaccountId.substring(4),
        date_preset: "maximum",
        fields: this.fields,
      });
    } catch (error) {
      this.error = error
    }

    await this.getReportStatus();
  },
  watch: {
    async report_run_status(status) {
      while (
        status === null ||
        status == "Job Not Started" ||
        status == "Job Started" ||
        status == "Running"
      ) {
        this.$store.commit("makingApiRequest", true);

        await this.getReportStatus();
        if (this.stopLooping) break;
      }

      if (status == "Job Completed") {
        this.stopLooping = true;
        //Get Adaccount Insights
        try {
          this.$store.commit("makingApiRequest", true);
          await this.$store.dispatch("facebook/getAdaccountInsights", {
            report_run_id: this.report_run_id,
          });
          this.$store.commit("makingApiRequest", false);
        } catch (error) {
          this.error = error
          this.$store.commit("makingApiRequest", false);
        }
        return;
      }
    },
  },
  computed: {
    ad_account() {
      return this.$store.state.facebook.single_ad_account;
    },
    facebook_adaccount_insights() {
      return this.$store.state.facebook.facebook_adaccount_insights;
    },
    report_run_id() {
      return this.$store.state.facebook.adaccount_report_run_id;
    },
    report_run_status() {
      return this.$store.state.facebook.adaccount_report_status;
    },
  },
  async beforeDestroy() {
    let payload = {};
    payload.facebook_adaccount_insights = null;
    await this.$store.commit("facebook/setFacebookAdaccountInsights", payload);
  },
};
</script>

<style scoped>
.min-height {
  min-height: 358px;
}
</style>
